import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import NavigationButton from '../components/navigation-button';

const TopCoders = () => (
  <Layout>
    <SEO
      title="Top Coders | Code Championship"
      description="The top coders in the United States under 15 years old. The Best of the best at Code Championship. Our top coders are an elite group of incredibly talented thinkers."
      keywords={['top', 'ranking', 'coders', 'united states', 'nation', 'best']}
    />
    <h1>Top Coders</h1>
    <div className="stripe-hr thin" />
    <p>
      Of the hundreds of coders who have played with us over the years,
      these are the top coders of Code Championship (so far).
      Not only have these competitors shown incredible coding skill, but their
      friendliness in the heat of competition has
      welcomed more coders to the sport than ever before!
    </p>
    <h2>
      2023 National Tournament
    </h2>
    <div className="stripe-hr thin" />
    <div className="two-wide-container">
      <div>
        <div className="top-coders-panel" style={{ fontSize: '3rem' }}>
          <div className="top-coders-panel-title">1</div>
          <div className="top-coders-panel-body">Jefferey E</div>
        </div>
        <div className="top-coders-panel" style={{ fontSize: '2rem' }}>
          <div className="top-coders-panel-title">2</div>
          <div className="top-coders-panel-body">Arlo W</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Mason D</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Raylen R</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Anders Y</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Joey A</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Josh X</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Lucas M</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Maxwell T</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">PQ</div>
          <div className="top-coders-panel-body">Zamiriddin Z</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">PQ</div>
          <div className="top-coders-panel-body">Ezoza Z</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">PQ</div>
          <div className="top-coders-panel-body">Joseph S</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">PQ</div>
          <div className="top-coders-panel-body">Gavin G</div>
        </div>
      </div>
      <div>
        <p>
          A year full of returning veterans rising to the top, some having played for years to finally qualify
          for their first national championship,
          2023 was dominated by familiar names hoping to capture their first national championship title. One did.
        </p>
        <p>
          Like in 2022, there were only two ways to make it into the tournament:
        </p>
        <ul>
          <li>Tournament Winner (TW) - win a regular season tournament</li>
          <li>
            Points Qualifier (PQ) - score the most points throughout the regular season
            {'. ('}
            <small>
              <Link href="/scoring">Learn more about scoring</Link>
            </small>
            {')'}
          </li>
        </ul>
        <p>
          Only a small percentage made it to the National Tournament,
          so we have included them here as a recognition of their hard work.
          With the exception of the top two finishers,
          players are sorted by their points scored during the 2023 regular season.
        </p>
        <p>
          Coming in to 2023, Mason D was looking to defend his title from 2022.
          Jeffrey E faced Mason in the semi-finals and with 16 seconds left, the game was tied.
          Jeffrey&apos;s bot switched into heavy use of the &quot;Mines&quot; in Space Duel
          and ended up advancing to the final match.
        </p>
        <p>
          Coming in to 2023, Mason D was looking to defend his title from 2022.
          Jeffrey faced Mason in the semi-finals and with 16 seconds left, the game was tied.
          Jeffrey&apos;s bot switched into heavy use of the &quot;Mines&quot; in Space Duel
          and ended up advancing to the final match and used the same strategy to win against Arlo
          who had played Ezoza in the other semi-final matchup.
        </p>
        <p>
          Ezoza, Mason, Jeffrey, and Arlo are all returned in fall 2024. The
          {' '}
          <Link href="/join">Fall 2024 Tournament Series</Link>
          {' '}
          is the next chance to earn your spot on this list and in the National Tournament.
        </p>
      </div>
    </div>
    <h2>
      2022 National Tournament
    </h2>
    <div className="stripe-hr thin" />
    <div className="two-wide-container">
      <div>
        <div className="top-coders-panel" style={{ fontSize: '3rem' }}>
          <div className="top-coders-panel-title">1</div>
          <div className="top-coders-panel-body">Mason D</div>
        </div>
        <div className="top-coders-panel" style={{ fontSize: '2rem' }}>
          <div className="top-coders-panel-title">2</div>
          <div className="top-coders-panel-body">Nico L</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Mingchi M</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Lucas M</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Sebby B</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Alexander L</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Cinar A</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Seamus O</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">PQ</div>
          <div className="top-coders-panel-body">Josh P</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">PQ</div>
          <div className="top-coders-panel-body">Remi C</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">PQ</div>
          <div className="top-coders-panel-body">Maxwell T</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">PQ</div>
          <div className="top-coders-panel-body">Jefferey E</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">PQ</div>
          <div className="top-coders-panel-body">Alyx G</div>
        </div>
      </div>
      <div>
        <p>
          With several veterans in their final year of eligibility, the competition for the top
          spot was tougher than ever. Like in 2021,
          there were only two ways to make it into the tournament:
        </p>
        <ul>
          <li>Tournament Winner (TW) - win a regular season tournament</li>
          <li>
            Points Qualifier (PQ) - score the most points throughout the regular season
            {'. ('}
            <small>
              <Link to="/scoring-details/">Learn more about scoring</Link>
            </small>
            {')'}
          </li>
        </ul>
        <p>
          Only a small percentage made it to the National Tournament,
          so we have included them here as a recognition of their hard work.
          With the exception of the top two finishers,
          players are sorted by their points scored during the 2022 regular season.
        </p>
        <p>
          Coming in to 2022, Nico was looking to defend his title from 2021.
          Mason had lost to Nico in the first round of the 2021 National Tournament.
          This high-stakes rematch ended in a tie. In Code Championship, efficient code is highly valued,
          and Mason won the tie-breaker having fewer blocks than Nico.
          Nico and Mason are both returned for the 2023 season.
        </p>
        <p>
          The
          {' '}
          <Link to="/fall-2024-tournament-series/">Fall 2024 Tournament Series</Link>
          {' '}
          is the next chance to earn your spot on this list and in the National Tournament.
        </p>
        <NavigationButton
          path="/fall-2024-tournament-series/"
          text="Join the Series"
        />
      </div>
    </div>
    <h2>
      2021 National Tournament
    </h2>
    <div className="stripe-hr thin" />
    <div className="two-wide-container">
      <div>
        <div className="top-coders-panel" style={{ fontSize: '3rem' }}>
          <div className="top-coders-panel-title">1</div>
          <div className="top-coders-panel-body">Nico L</div>
        </div>
        <div className="top-coders-panel" style={{ fontSize: '2rem' }}>
          <div className="top-coders-panel-title">2</div>
          <div className="top-coders-panel-body">Sebby B</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Erik V</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Mingchi M</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Maxwell T</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Mason D</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Alyx G</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Kellen  K</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">TW</div>
          <div className="top-coders-panel-body">Dewayne B</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">PQ</div>
          <div className="top-coders-panel-body">Hansika N</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">PQ</div>
          <div className="top-coders-panel-body">Advika C</div>
        </div>
      </div>
      <div>
        <p>
          With more competitors than ever before, the stakes were raised in the 2021 season.
          There were only two ways to make it into the tournament:
        </p>
        <ul>
          <li>Tournament Winner (TW) - win a regular season tournament</li>
          <li>
            Points Qualifier (PQ) - score the most points throughout the regular season
            {'. ('}
            <small>
              <Link to="/scoring-details/">Learn more about scoring</Link>
            </small>
            {')'}
          </li>
        </ul>
        <p>
          With more competitors than ever before, only a small percentage made it to the National Tournament,
          so we have included them here as a recognition of their hard work. With the exception of the top two finishers,
          players are sorted by their points scored during the 2021 regular season.
        </p>
        <p>
          Nico L and Sebby B squared off in the final match of 2021 after earning their places in the National Tournament
          as regular season tournament winners and winning multiple pool and bracket play games.
          Nico clinched this year&apos;s title with a never-before-seen "two bot" strategy.
          This set the stage for a new level of play for the coders in our 2022 Spring Tournament Series.
        </p>
        <p>
          The
          {' '}
          <Link to="/fall-2024-tournament-series/">Fall 2024 Tournament Series</Link>
          {' '}
          is the next chance to earn your spot on this list and in the National Tournament.
        </p>
        <NavigationButton
          path="/fall-2024-tournament-series/"
          text="Join the Series"
        />
      </div>
    </div>
    <h2>
      2020 National Tournament
    </h2>
    <div className="stripe-hr thin" />
    <div className="two-wide-container">
      <div>
        <div className="top-coders-panel" style={{ fontSize: '3rem' }}>
          <div className="top-coders-panel-title">1</div>
          <div className="top-coders-panel-body">Aaron B</div>
        </div>
        <div className="top-coders-panel" style={{ fontSize: '2rem' }}>
          <div className="top-coders-panel-title">2</div>
          <div className="top-coders-panel-body">Sebby B</div>
        </div>
        <div className="top-coders-panel">
          <div className="top-coders-panel-title">3</div>
          <div className="top-coders-panel-body">Josh P</div>
        </div>
      </div>
      <div>
        <p>
          If you&apos;ve been a part of Code Championship for long, you know these three names.
          2020 was the first season where competitors needed to win a regular season tournament
          to earn a place in the National Tournament - qualifying through points wasn&apos;t an option
          in 2020, so Aaron, Sebby, and Josh all won at least one tournament during the regular season.
          These three Code Championship veterans fought for the title.
        </p>
        <p>
          Aaron and Sebby had both shown impressive week-over-week performances - both won multiple tournaments during the regular season.
          Sebby made the final match for his second time, setting up an incredible final matchup between two of the all-time greatest
          Code Championship coders. Aaron managed to win the final match and earned his place as the 2020 National Tournament winner.
        </p>
      </div>
    </div>
    <h2>
      2019 National Tournament
    </h2>
    <div className="stripe-hr thin" />
    <div className="two-wide-container">
      <div>
        <div className="top-coders-panel" style={{ fontSize: '3rem' }}>
          <div className="top-coders-panel-title">1</div>
          <div className="top-coders-panel-body">Sebby B</div>
        </div>
      </div>
      <div>
        <p>
          In 2019, the National Tournament was split into two categories: one for 12 and under and one for 15 and under.
          Sebby B won both. Not only is Sebby one of the top coders of all time - with unmatched finishes in the Top 2 every year -
          his incredibly welcoming personality set the precedent for all future National Tournament winners.
        </p>
      </div>
    </div>
  </Layout>
);

export default TopCoders;
